/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Custom CSS */

@import url('https://fonts.googleapis.com/css?family=Bangers|Open+Sans&display=swap');

*, *:after, *:before {
	box-sizing: border-box;
	outline: none;
	-webkit-overflow-scrolling: touch;
	appearance: none;
}

body {
  -webkit-font-smoothing: antialiased;
  color: black;
}

#image {
  background-color: rgb(255, 234, 0);
  padding-top: 10vw;
  
  img {
    display: block;
    width: 100%;
  }
}

#text {
  padding: 15vw 10vw;
  text-align: center;

  h1 {
    font-family: 'Bangers', sans-serif;
    font-size: 30vw;
    font-weight: 400; 
    margin-bottom: 10vw;
    line-height: 0.8em;
    text-indent: -0.1em;
  }

  h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 8vw;
    font-weight: 400;
    line-height: 1.3em;
    margin-bottom: 10vw;

    a {
      color: black;
      text-decoration: none;
      transition: color .1s ease;

      &:hover {
        color: gray;
      }
    }
  }

  #links {

    a {
      cursor: pointer;
      display: inline-block;
      font-size: 7vw;

      i {
        transition: transform .2s ease;
        vertical-align: middle;
      }

      &:hover i {
        transform: translate3d(0, -0.2em, 0);
      }

      &:not(:last-child){
        margin-right: 5vw;
      }
    }
  }
}

@media (min-width: 1024px){

  #image {
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    img {
      position: absolute;
      bottom: 0;
    }
  }

  #text {
    width: 50%;
    height: 100%;
    overflow: auto;
    padding: 7vw;
    position: absolute;
    left: 50%;

    h1 {
      font-size: 15vw;
      margin-bottom: 5vw;
    }

    h2 {
      font-size: 3vw;
      margin-bottom: 5vw;
    }

    #links {
  
      a {
        font-size: 3vw;

        &:not(:last-child){
          margin-right: 2vw;
        }
      }
    }
  }
}